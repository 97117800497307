"use strict";

export const execute = () => {
  let lazyObjects = [].slice.call(document.querySelectorAll(".lazyload"));
  if ("IntersectionObserver" in window) {
    const lazyObjectObserver = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            const lazyObject = entry.target;
            if (lazyObject.classList.contains("lazyload")) {
              if (lazyObject.dataset.tweetid) {
                loadTwitter();
                loadTweet(lazyObject);
                lazyObjectObserver.unobserve(lazyObject);
              } else if (lazyObject.classList.contains("instagram-media-tmp")) {
                processIG(lazyObject);
                lazyObjectObserver.unobserve(lazyObject);
              } else if (lazyObject.classList.contains("yt-video")) {
                processYT(lazyObject);
              } else {
                if (lazyObject.tagName == "IFRAME") {
                  const srcIframe = lazyObject.dataset.src;
                  lazyObject.contentWindow.location.replace(
                    lazyObject.dataset.src
                  );

                  if (srcIframe.indexOf("widgets") > -1) {
                    iFrameResize(
                      {
                        checkOrigin: false,
                      },
                      'iframe[data-src*="widgets"]'
                    );
                  }
                } else {
                  lazyObject.src = lazyObject.dataset.src;
                }
                lazyObject.removeAttribute("data-src");
                if (lazyObject.dataset.srcset) {
                  lazyObject.srcset = lazyObject.dataset.srcset;
                  lazyObject.removeAttribute("data-srcset");
                }

                lazyObject.classList.remove("lazyload");
                lazyObjectObserver.unobserve(lazyObject);
              }
            }
          }
        });
      },
      {
        rootMargin: "0px 0px 256px 0px",
      }
    );
    lazyObjects.forEach(function (lazyImage) {
      lazyObjectObserver.observe(lazyImage);
    });
  } else {
    let active = false;
    const legacyLazyLoad = function () {
      if (active === false) {
        active = true;

        setTimeout(function () {
          lazyObjects.forEach(function (lazyImage) {
            if (
              lazyImage.getBoundingClientRect().top <= window.innerHeight &&
              lazyImage.getBoundingClientRect().bottom >= 0 &&
              getComputedStyle(lazyImage).display !== "none" &&
              lazyImage.classList.contains("lazyload")
            ) {
              if (lazyImage.dataset.tweetid) {
                loadTwitter();
                loadTweet(lazyImage);
              } else if (lazyImage.classList.contains("instagram-media-tmp")) {
                processIG(lazyImage);
              } else {
                if (lazyImage.tagName == "IFRAME") {
                  const srcIframe = lazyImage.dataset.src;
                  lazyImage.contentWindow.location.replace(
                    lazyImage.dataset.src
                  );

                  if (srcIframe.indexOf("widgets") > -1) {
                    iFrameResize(
                      {
                        checkOrigin: false,
                      },
                      'iframe[data-src*="widgets"]'
                    );
                  }
                } else {
                  lazyImage.src = lazyImage.dataset.src;
                }

                if (lazyImage.dataset.srcset) {
                  lazyImage.srcset = lazyImage.dataset.srcset;
                }
              }
              lazyImage.classList.remove("lazyload");
              lazyObjects = lazyObjects.filter(function (image) {
                return image !== lazyImage;
              });

              if (lazyObjects.length === 0) {
                document.removeEventListener("scroll", legacyLazyLoad);
                window.removeEventListener("resize", legacyLazyLoad);
                window.removeEventListener("orientationchange", legacyLazyLoad);
              }
            }
          });

          active = false;
        }, 200);
      }
    };

    document.addEventListener("scroll", legacyLazyLoad);
    window.addEventListener("resize", legacyLazyLoad);
    window.addEventListener("orientationchange", legacyLazyLoad);
    legacyLazyLoad();
  }
};

const loadTweet = (twDom) => {
  if (
    window.twttr &&
    window.twttr.widgets &&
    twDom.classList.contains("lazyload")
  ) {
    const twId = twDom.dataset.tweetid;
    const newEl = document.createElement("p");
    newEl.id = twId;
    twDom.after(newEl);
    twDom.classList.remove("lazyload");
    window.twttr.widgets
      .createTweet(twId, document.getElementById(twId), {})
      .then(() => {
        twDom.remove();
      });
  } else {
    setTimeout(() => {
      loadTweet(twDom);
    }, 1000);
  }
};
const loadTwitter = () => {
  window.twttr = (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);
    t._e = [];
    t.ready = function (f) {
      t._e.push(f);
    };
    return t;
  })(document, "script", "twitter-wjs");
};

const processIG = (el) => {
  if (window.instgrm) {
    insertIGPlayer(el);
  } else {
    insertIGScript(el);
  }
};

const insertIGScript = (el) => {
  const igOnLoad = () => {
    insertIGPlayer(el);
  };
  (function (d, s, id, onload) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id) || window.instgrm) return;
    js = d.createElement(s);
    js.id = id;
    js.setAttribute("async", "");
    js.src = "https://www.instagram.com/embed.js";
    fjs.parentNode.insertBefore(js, fjs);
    js.onload = onload;
  })(document, "script", "instagram-wjs", igOnLoad);
};

const insertIGPlayer = (el) => {
  el.className = "instagram-media";
  window.instgrm.Embeds.process();
};

let ytPendingVideos = [];
let ytIsInitialized = false;

const processYT = (el) => {
  if (ytIsInitialized) {
    insertYTPlayer(el);
  } else {
    if (!ytPendingVideos.length) {
      insertYTScript();
      window.onYouTubeIframeAPIReady = function () {
        ytIsInitialized = true;
        ytPendingVideos.forEach((v) => {
          insertYTPlayer(v);
        });
      };
    }
    ytPendingVideos.push(el);
  }
};

const insertYTScript = () => {
  let tag = document.createElement("script");
  tag.src = "https://www.youtube.com/iframe_api";
  let firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

const insertYTPlayer = (el) => {
  const _ = new YT.Player(el, {
    height: el.dataset.height,
    width: el.dataset.width,
    videoId: el.dataset.videoId,
  });
};

export const lazyload = () => {
  document.addEventListener("lrload", function () {
    execute();
  });
};
export default lazyload;
