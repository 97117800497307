import { changeTransform } from "./../functions/general-functions";

import { DESKTOP_QUERY } from "./../constants/constants";

let lastKnownPosition = window.scrollY;
let ticking = false;

const scrollFunction = () => {
  lastKnownPosition = window.scrollY;
  if (!ticking) {
    window.requestAnimationFrame(function() {
      stickyScroll(lastKnownPosition);
      ticking = false;
    });
  }
  ticking = true;
};

const stickyScroll = (position) => {
  const a = document.getElementById("sticky-anchor-2");
  const b = document.getElementById("sticky-anchor-1");

  if (a && b) {
    let rh = 0;
    if (a.getAttribute("data-rel-id")) {
      rh = document.getElementById(a.getAttribute("data-rel-id")).offsetHeight
        ? document.getElementById(a.getAttribute("data-rel-id")).offsetHeight
        : 0;
    }

    const ah = a.offsetHeight + rh;
    const bh = b.offsetHeight;
    const diff = Math.abs(ah - bh);
    const scrollToTop = position + 116;

    if (scrollToTop > b.offsetTop) {
      let c = scrollToTop - b.offsetTop;
      c = Math.round(c);
      c = c < 0 ? 0 : c > diff ? diff : c;
      changeTransform(a, c);
    } else if (position == 0) {
      changeTransform(a, 0);
    }
  }
};

const screenTest = (e) => {
  if (e.matches) {
    window.addEventListener("scroll", scrollFunction);
  } else {
    changeTransform(document.getElementById("sticky-anchor-2"));
    window.removeEventListener("scroll", scrollFunction);
  }
};

export default () => {
  document.addEventListener("lrload", function() {
    const mql = window.matchMedia(DESKTOP_QUERY);
    mql.addEventListener("change", screenTest);
    screenTest(mql);
  });
};
