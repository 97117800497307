<template>
  <div>
    <button v-if="!isAdded" @click="addTopic" class="btn add">
      AGREGAR A MIS TEMAS
      <div><i class="icon-add"></i></div>
    </button>
    <a
      v-if="isAdded"
      target="_blank"
      rel="noopener"
      class="btn"
      href="https://mas.larepublica.co/noticias/editar-top-20"
      >TEMA AGREGADO</a
    >
  </div>
</template>

<script>
import store from "../../store";
import { mapGetters } from "vuex";
export default {
  created() {
    this.tagsInfo = tagInfo;
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
      userTopics: "topics",
      token: "token",
    }),
    isAdded() {
      return (
        this.userTopics &&
        this.userTopics.some((q) => q.topicId == this.tagsInfo.id)
      );
    },
  },
  methods: {
    addTopic: function () {
      this.$emit("added");
    },
  },
};
</script>