import { initMain } from "./vue/controllers/main";
import { header } from "./vue/controllers/header";

import lazyload from "./features/lazy-load";
import stickyScroll from "./features/sticky-scroll";
import { topicMixin } from "./vue/mixins/lrplus";
import AddTopic from "./vue/components/add-topic/AddTopic.vue";

lazyload();
stickyScroll();
const mixins = [topicMixin];

initMain(mixins, {
  SectionPager: () => import("./vue/components/section-pager/SectionPager.vue"),
  SubscribeModal: () =>
    import("./vue/components/subscribe-modal/SubscribeModal.vue"),
  TrendsHorizontal: () =>
    import("./vue/components/trends/TrendsHorizontal.vue"),
  AddTopic,
});
header();
